import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { LoginComponent } from './views/pages/login/login.component';

import { AuthGuard } from './shared/guard/auth.guard';
import { ResetPasswordComponent } from './views/pages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './views/pages/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'notificacao',
        loadChildren: () =>
          import('./views/pages/notification/notification.module').then((m) => m.NotificationModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'roteirizador',
        loadChildren: () =>
          import('./views/pages/expert-routes/expert-routes.module').then((m) => m.ExpertRoutesModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'controle-atraso',
        loadChildren: () =>
          import('./views/pages/delay-control/delay-control.module').then((m) => m.DelayControlModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'regulacao-auditoria',
        loadChildren: () =>
          import('./views/pages/regulation-audit/regulation-audit.module').then((m) => m.RegulationAuditModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'relatorios-gerencial',
        loadChildren: () =>
          import('./views/pages/power-bi/power-bi.module').then((m) => m.PowerBIModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'regulacao',
        loadChildren: () =>
          import('./views/pages/regulation/regulation.module').then((m) => m.RegulationModule)
      },
      {
        path: 'controle-analise',
        loadChildren: () =>
          import('./views/pages/regulation/control-analize/control-analize.module').then((m) => m.ControlAnalizeModule)
      },
      {
        path: 'administracao',
        loadChildren: () =>
          import('./views/pages/administration/administration.module').then((m) => m.AdministrationModule)
      },
      {
        path: 'configuracoes',
        loadChildren: () =>
          import('./views/pages/configuration/configuration.module').then((m) => m.ConfigurationModule)
      }
    ]
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Recuperação de senha'
    }
  },
  {
    path: 'change-password/:token/:code',
    component: ChangePasswordComponent,
    data: {
      title: 'Alteração de senha'
    }
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
