import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibFacebook,
  cibGoogle,
  cibLinkedin,
  cibSkype,
  cibTwitter,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilChart,
  cilChartPie,
  cilCheck,
  cilChevronLeft,
  cilChevronRight,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilFile,
  cilGrid,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLayers,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilSearch,
  cilDescription,
  cilBarChart,
  cilCog,
  cilCloudUpload,
  cilBalanceScale,
  cilShieldAlt,
  cilSwapHorizontal,
  cilXCircle,
  cilAccountLogout,
  cilArrowThickFromLeft,
  cilCheckAlt,
  cilX,
  cilInput,
  cilSend,
  cilBook,
  cilImage,
  cilPlus,
  cilMinus,
  cilCircle,
  cilBullhorn,
  cilClipboard,
  cilExternalLink
} from '@coreui/icons';

import { 
  cibSlickpic, 
  cisUser, 
  cisPhone,
  cisLockLocked,
  cidTrashSweep,
  cisCheck,
  cidLoopCircular,
  cisGroup,
  cisCar,
  cisGarage,
  cidUserFollow,
  cidFolderPlus,
  cisChevronRight,
  cisChevronLeft,
  cilChevronDoubleRight,
  cilChevronDoubleLeft,
  cilInfo,
  cidExclamationCircle,
  cilColorPalette,
  cilChevronTopAlt,
  cilChevronBottomAlt,
  cidEye,
  cidEyeSlash,
  cisCloudDownload,
  cilDocument,
  cilTarget,
  cisChevronBottomAlt,
  cisChevronTopAlt,
  cisHistory,
  cilClock,
  cisCheckDouble,
  cidUpdate,
  cibWhatsapp,
  cilHandshake,
  cilFilePdf,
  cilBan,
  cisDocument,
  cisWallpaper,
  cibCircle,
  cilReload,
  cilFilter,
  cilFilterX,
  cilCommentSquareLines,
  cilFeaturedPlaylist,
  cidChart,
  cidCog,
  cilCopy,
  cilStickyNote,
  cilTransfer
} from '@coreui/icons-pro';

export const iconSubset = {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibFacebook,
  cibGoogle,
  cibLinkedin,
  cibSkype,
  cibTwitter,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilChart,
  cilChartPie,
  cilCheck,
  cilChevronLeft,
  cilChevronRight,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilFile,
  cilGrid,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLayers,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilSearch,
  cilDescription,
  cilBarChart,
  cilCog,
  cilCloudUpload,
  cilBalanceScale,
  cilShieldAlt,
  cilSwapHorizontal,
  cilXCircle,
  cilAccountLogout,
  cilArrowThickFromLeft,
  cilCheckAlt,
  cilX,
  cilInput,
  cilSend,
  cilBook,
  cilImage,
  cilPlus,
  cilMinus,
  cilCircle,
  cibSlickpic,
  cisUser,
  cisLockLocked,
  cidTrashSweep,
  cisCheck,
  cidLoopCircular,
  cisGroup,
  cisCar,
  cilBullhorn,
  cilClipboard,
  cilExternalLink,
  cisGarage,
  cidUserFollow,
  cidFolderPlus,
  cisChevronRight,
  cisChevronLeft,
  cilChevronDoubleRight,
  cilChevronDoubleLeft,
  cilInfo,
  cidExclamationCircle,
  cilColorPalette,
  cilChevronTopAlt,
  cilChevronBottomAlt,
  cidEye,
  cidEyeSlash,
  cisCloudDownload,
  cilDocument,
  cilTarget,
  cisChevronBottomAlt,
  cisChevronTopAlt,
  cisHistory,
  cilClock,
  cisCheckDouble,
  cidUpdate,
  cibWhatsapp,
  cilHandshake,
  cilFilePdf,
  cilBan,
  cisDocument,
  cisWallpaper,
  cibCircle,
  cilReload,
  cilFilter,
  cilFilterX,
  cilCommentSquareLines,
  cilFeaturedPlaylist,
  cidChart,
  cidCog,
  cilCopy,
  cilStickyNote,
  cilTransfer
};

export enum IconSubset {
  cibCcAmex = 'cibCcAmex',
  cibCcApplePay = 'cibCcApplePay',
  cibCcMastercard = 'cibCcMastercard',
  cibCcPaypal = 'cibCcPaypal',
  cibCcStripe = 'cibCcStripe',
  cibCcVisa = 'cibCcVisa',
  cibFacebook = 'cibFacebook',
  cibGoogle = 'cibGoogle',
  cibLinkedin = 'cibLinkedin',
  cibSkype = 'cibSkype',
  cibTwitter = 'cibTwitter',
  cifBr = 'cifBr',
  cifEs = 'cifEs',
  cifFr = 'cifFr',
  cifIn = 'cifIn',
  cifPl = 'cifPl',
  cifUs = 'cifUs',
  cilAlignCenter = 'cilAlignCenter',
  cilAlignLeft = 'cilAlignLeft',
  cilAlignRight = 'cilAlignRight',
  cilApplicationsSettings = 'cilApplicationsSettings',
  cilArrowBottom = 'cilArrowBottom',
  cilArrowRight = 'cilArrowRight',
  cilArrowTop = 'cilArrowTop',
  cilBasket = 'cilBasket',
  cilBell = 'cilBell',
  cilBold = 'cilBold',
  cilBookmark = 'cilBookmark',
  cilCalculator = 'cilCalculator',
  cilCalendar = 'cilCalendar',
  cilChart = 'cilChart',
  cilChartPie = 'cilChartPie',
  cilCheck='cilCheck',
  cilChevronLeft = 'cilChevronLeft',
  cilChevronRight = 'cilChevronRight',
  cilCloudDownload = 'cilCloudDownload',
  cilCode = 'cilCode',
  cilCommentSquare = 'cilCommentSquare',
  cilCreditCard = 'cilCreditCard',
  cilCursor = 'cilCursor',
  cilDollar = 'cilDollar',
  cilDrop = 'cilDrop',
  cilEnvelopeClosed = 'cilEnvelopeClosed',
  cilEnvelopeOpen = 'cilEnvelopeOpen',
  cilFile = 'cilFile',
  cilGrid = 'cilGrid',
  cilHome = 'cilHome',
  cilInbox = 'cilInbox',
  cilIndentDecrease = 'cilIndentDecrease',
  cilIndentIncrease = 'cilIndentIncrease',
  cilItalic = 'cilItalic',
  cilJustifyCenter = 'cilJustifyCenter',
  cilLayers = 'cilLayers',
  cilList = 'cilList',
  cilListNumbered = 'cilListNumbered',
  cilLocationPin = 'cilLocationPin',
  cilLockLocked = 'cilLockLocked',
  cilMagnifyingGlass = 'cilMagnifyingGlass',
  cilMap = 'cilMap',
  cilMediaPlay = 'cilMediaPlay',
  cilMenu = 'cilMenu',
  cilMoon = 'cilMoon',
  cilNotes = 'cilNotes',
  cilOptions = 'cilOptions',
  cilPaperclip = 'cilPaperclip',
  cilPaperPlane = 'cilPaperPlane',
  cilPen = 'cilPen',
  cilPencil = 'cilPencil',
  cilPeople = 'cilPeople',
  cilPrint = 'cilPrint',
  cilPuzzle = 'cilPuzzle',
  cilReportSlash = 'cilReportSlash',
  cilSave = 'cilSave',
  cilSettings = 'cilSettings',
  cilShare = 'cilShare',
  cilShareAll = 'cilShareAll',
  cilShareBoxed = 'cilShareBoxed',
  cilSpeech = 'cilSpeech',
  cilSpeedometer = 'cilSpeedometer',
  cilSpreadsheet = 'cilSpreadsheet',
  cilStar = 'cilStar',
  cilSun = 'cilSun',
  cilTags = 'cilTags',
  cilTask = 'cilTask',
  cilTrash = 'cilTrash',
  cilUnderline = 'cilUnderline',
  cilUser = 'cilUser',
  cilUserFemale = 'cilUserFemale',
  cilUserFollow = 'cilUserFollow',
  cilUserUnfollow = 'cilUserUnfollow',
  cilSearch = 'cilSearch',
  cilDescription = 'cilDescription',
  cilBarChart = 'cilBarChart',
  cilCog = 'cilCog',
  cilCloudUpload = 'cilCloudUpload',
  cilBalanceScale = 'cilBalanceScale',
  cilShieldAlt = 'cilShieldAlt',
  cilSwapHorizontal = 'cilSwapHorizontal',
  cilXCircle = 'cilXCircle',
  cilAccountLogout = 'cilAccountLogout',
  cilArrowThickFromLeft = 'cilArrowThickFromLeft',
  cilCheckAlt = 'cilCheckAlt',
  cilX = 'cilX',
  cilInput = 'cilInput',
  cilSend = 'cilSend',
  cilBook = 'cilBook',
  cilImage = 'cilImage',
  cilPlus = 'cilPlus',
  cilMinus = 'cilMinus',
  cilCircle = 'cilCircle',
  cibSlickpic = 'cibSlickpic',
  cisPhone = 'cisPhone',
  cisUser = 'cisUser',
  cisLockLocked = 'cisLockLocked',
  cidTrashSweep = 'cidTrashSweep',
  cisCheck = 'cisCheck',
  cidLoopCircular = 'cidLoopCircular',
  cisGroup = 'cisGroup',
  cisCar = 'cisCar',
  cilBullhorn = 'cilBullhorn',
  cilClipboard = 'cilClipboard',
  cilExternalLink = 'cilExternalLink',
  cisGarage = 'cisGarage',
  cidUserFollow = 'cidUserFollow',
  cidFolderPlus = 'cidFolderPlus',
  cisChevronRight = 'cisChevronRight',
  cisChevronLeft = 'cisChevronLeft',
  cilChevronDoubleRight = 'cilChevronDoubleRight',
  cilChevronDoubleLeft = 'cilChevronDoubleLeft',
  cilInfo = 'cilInfo',
  cidExclamationCircle = 'cidExclamationCircle',
  cilColorPalette = 'cilColorPalette',
  cilChevronTopAlt = 'cilChevronTopAlt',
  cilChevronBottomAlt = 'cilChevronBottomAlt',
  cidEye = 'cidEye',
  cidEyeSlash = 'cidEyeSlash',
  cisCloudDownload = 'cisCloudDownload',
  cilDocument = 'cilDocument',
  cilTarget = 'cilTarget',
  cisChevronBottomAlt = 'cisChevronBottomAlt',
  cisChevronTopAlt = 'cisChevronTopAlt',
  cisHistory = 'cisHistory',
  cilClock = 'cilClock',
  cisCheckDouble = 'cisCheckDouble',
  cidUpdate = 'cidUpdate',
  cibWhatsapp = 'cibWhatsapp',
  cilHandshake = 'cilHandshake',
  cilFilePdf = 'cilFilePdf',
  cilBan = 'cilBan',
  cisDocument = 'cisDocument',
  cisWallpaper = 'cisWallpaper',
  cibCircle = 'cibCircle',
  cilReload = 'cilReload',
  cilFilter = 'cilFilter',
  cilFilterX = 'cilFilterX',
  cilCommentSquareLines = 'cilCommentSquareLines',
  cilFeaturedPlaylist = 'cilFeaturedPlaylist',
  cidChart = 'cidChart',
  cidCog = 'cidCog',
  cilCopy = 'cilCopy',
  cilStickyNote = 'cilStickyNote',
  cilTransfer = 'cilTransfer'
}
