export const roles = {
  /** @description MENU ADMINISTRAÇÃO */
  MENU_ADMINISTRATION: 'MENU_ADMINISTRATION',
  /** @description MENU CONFIGURAÇÕES */
  MENU_CONFIGURATION: 'MENU_CONFIGURATION',
  /** @description MENU DISTRIBUIÇÃO */
  MENU_DISTRIBUTION: 'MENU_DISTRIBUTION',
  /** @description MENU ROUTEIRIZAÇÃO */
  MENU_EXPERTROUTES: 'MENU_EXPERTROUTES',
  /** @description MENU CONTROLE DE ANÁLISE */
  MENU_CTRLANALYSE: 'MENU_CTRLANALYSE',
  /** @description MENU IMPORTAÇÕES */
  MENU_IMPORTS: 'MENU_IMPORTS',
  /** @description MENU VISTORIA */
  MENU_INSPECTION: 'MENU_INSPECTION',
  /** @description MENU FAROL */
  MENU_LIGHTHOUSE: 'MENU_LIGHTHOUSE',
  /** @description MENU MONITORIA */
  MENU_MONITORING: 'MENU_MONITORING',
  /** @description MENU RELATÓRIOS */
  MENU_REPORTS: 'MENU_REPORTS',
  /** @description MENU AUDITORIA DE SINISTROS */
  MENU_AUDIT_SINISTER: 'MENU_AUDIT_SINISTER',
  /** @description MENU SINISTROS */
  MENU_SINISTER: 'MENU_SINISTER',
  /** @description MENU_AGREEMENT */
  MENU_AGREEMENT: 'MENU_AGREEMENT',
  /** @description MENU_IMAGE_MONITORING */
  MENU_IMAGE_MONITORING: 'MENU_IMAGE_MONITORING',
  /** @description MENU_AGREEMENT_SEARCH */
  MENU_AGREEMENT_SEARCH: 'MENU_AGREEMENT_SEARCH',
  /** @description REG - ABA ANÁLISE (LEITURA) */
  RD_REG_TABANALYZE: 'RD_REG_TABANALYZE',
  /** @description REG - ABA DETALHES (LEITURA) */
  RD_REG_TABDETAILS: 'RD_REG_TABDETAILS',
  /** @description REG - ABA DIGITAÇÃO (LEITURA) */
  RD_REG_TABDIGITATION: 'RD_REG_TABDIGITATION',
  /** @description REG - ABA REGULAÇÃO (LEITURA) */
  RD_REG_TABREGULATION: 'RD_REG_TABREGULATION',
  /** @description REG - ABA AGENDAMENTOS (LEITURA) */
  RD_REG_TABSCHEDULING: 'RD_REG_TABSCHEDULING',
  /** @description REG - ABA RETORNO OFICINA (LEITURA) */
  RD_REG_TABWORKSHOP: 'RD_REG_TABWORKSHOP',
  /** @description ADM - CONTAS */
  RW_ADM_ACCOUNTS: 'RW_ADM_ACCOUNTS',
  /** @description ADM - GRUPOS */
  RW_ADM_GROUPS: 'RW_ADM_GROUPS',
  /** @description ADM - LOGS */
  RW_ADM_LOGS: 'RW_ADM_LOGS',
  /** @description ADM - PERMISSÕES */
  RW_ADM_PERMISSIONS: 'RW_ADM_PERMISSIONS',
  /** @description CONF - AREA */
  RW_CFG_AREA: 'RW_CFG_AREA',
  /** @description CONF - MARCA DE VEÍCULOS */
  RW_CFG_AUTOBRAND: 'RW_CFG_AUTOBRAND',
  /** @description CONF - MODELOS DE VEÍCULOS */
  RW_CFG_AUTOMODEL: 'RW_CFG_AUTOMODEL',
  /** @description CONF - TIPO DE VEÍCULOS */
  RW_CFG_AUTOTYPE: 'RW_CFG_AUTOTYPE',
  /** @description CONF - BANCOS */
  RW_CFG_BANK: 'RW_CFG_BANK',
  /** @description CONF - FILIAIS */
  RW_CFG_BRANCH: 'RW_CFG_BRANCH',
  /** @description CONF - SISTEMA DE ORÇAMENTAÇÃO */
  RW_CFG_BUDGETSYSTEM: 'RW_CFG_BUDGETSYSTEM',
  /** @description CONF - TIPO DE NEGÓCIO */
  RW_CFG_BUSINESSTYPE: 'RW_CFG_BUSINESSTYPE',
  /** @description CONF - TIPO DE DOCUMENTOS */
  RW_CFG_DOCUMENTTYPE: 'RW_CFG_DOCUMENTTYPE',
  /** @description CONF - REGULADOR */
  RW_CFG_EXPERT: 'RW_CFG_EXPERT',
  /** @description CONF - FERIADOS */
  RW_CFG_HOLIDAY: 'RW_CFG_HOLIDAY',
  /** @description CONF - TIPO DE IMAGENS */
  RW_CFG_IMAGESTTYPE: 'RW_CFG_IMAGESTTYPE',
  /** @description CONF - SEGURADO */
  RW_CFG_INSURED: 'RW_CFG_INSURED',
  /** @description CONF - SEGURADORAS */
  RW_CFG_INSURERS: 'RW_CFG_INSURERS',
  /** @description CONF - TIPO DE VISTORIA */
  RW_CFG_REGULATIONTYPE: 'RW_CFG_REGULATIONTYPE',
  /** @description CONF - AGENDADO COM (CONTATO) */
  RW_CFG_SCHEDULINGCONTACT: 'RW_CFG_SCHEDULINGCONTACT',
  /** @description CONF - FORMA DE VISTORIA */
  RW_CFG_SCHEDULINGTYPE: 'RW_CFG_SCHEDULINGTYPE',
  /** @description CONF - REGIÕES DE ATENDIMENTO */
  RW_CFG_SERVICEREGIONS: 'RW_CFG_SERVICEREGIONS',
  /** @description CONF - ESTÁGIOS */
  RW_CFG_STAGES: 'RW_CFG_STAGES',
  /** @description CONF - OFICINA */
  RW_CFG_WORKSHOP: 'RW_CFG_WORKSHOP',
  /** @description REG - ABA ANÁLISE (ESCRITA) */
  RW_REG_TABANALYZE: 'RW_REG_TABANALYZE',
  /** @description REG - ABA DETALHES (ESCRITA) */
  RW_REG_TABDETAILS: 'RW_REG_TABDETAILS',
  /** @description REG - ABA DIGITAÇÃO (ESCRITA) */
  RW_REG_TABDIGITATION: 'RW_REG_TABDIGITATION',
  /** @description REG - ABA REGULAÇÃO (ESCRITA) */
  RW_REG_TABREGULATION: 'RW_REG_TABREGULATION',
  /** @description REG - ABA AGENDAMENTOS (ESCRITA) */
  RW_REG_TABSCHEDULING: 'RW_REG_TABSCHEDULING',
  /** @description REG - ABA PESQUISA */
  RW_REG_TABSEARCH: 'RW_REG_TABSEARCH',
  /** @description REG - ABA RETORNO OFICINA (ESCRITA) */
  RW_REG_TABWORKSHOP: 'RW_REG_TABWORKSHOP',
  /** @descriptionREG FUN - DESBLOQUEAR REGULAÇÃO */
  FUN_UNLOCKREG: 'FUN_UNLOCKREG',
  /** @descriptionREG IMG FUN - Deletar Imagem */
  FUN_DELETEIMAGE: 'FUN_DELETEIMAGE',
  FUN_ZEZIN: 'FUN_ZEZIN',
  FUN_DELETE_SINISTER: 'FUN_DELETE_SINISTER',
  /** @description REG - BOTÃO CILIA ANALYZE (ESCRITA) */
  RW_REG_BUTTON_CILIA_ANALYZE: 'RW_REG_BUTTON_CILIA_ANALYZE'
}
