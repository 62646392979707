import { Pipe, PipeTransform } from '@angular/core';
import nodeTypes from 'src/app/shared/data/note-type.data';

@Pipe({
    name: 'noteType'
})
export class NoteTypePipe implements PipeTransform {

    public nodeTypes = nodeTypes;

    /**
     * Método responsável por formatar as notas.
     * @param {string} description 
     * @returns {string} 
     * @memberof NoteTypePipe
     */
    transform(description: string): string {

        let element = nodeTypes.find(el => el.value == description);

        if (element) {
            return element.label;
        } 

        return description;
    }
}