import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { ItemSelect } from '../models/item-select.model';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  getDateConvert(date: any, format: string = 'yyyy-MM-dd'): any {
    if (date != undefined || date != null) {
      return formatDate(date, format,"en-US") + 'T00:00:00.000Z'
    }
    
    return null;
  }

  getDateConvertEndDay(date: any, format: string = 'yyyy-MM-dd'): any {
    if (date != undefined || date != null) {
      return formatDate(date, format,"en-US") + 'T23:59:59.000Z'
    }
    
    return null;
  }

  getAllLabelValue(label: string, value: string, items: Array<any>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status']) {
        list.push(new ItemSelect(item[label], item[value]));
      }
    }

    return list;
  }

  getAllLabelValueWhitOutStatus(label: string, value: string, items: Array<any>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value)) {
        list.push(new ItemSelect(item[label], item[value]));
      }
    }

    return list;
  }

  findItemIdByValue(value: string, items: Array<any>): string | undefined {
    let valueNormalize = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    const item = items.find(item => item.label.normalize('NFD').replace(/[\u0300-\u036f]/g, "") === valueNormalize);
    return item ? item.value : undefined;
  }

  getAllLabelValueShowInactives(label: string, value: string, items: Array<any>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value)) {
        if (item.hasOwnProperty('status') && item['status']) {
          list.push(new ItemSelect(item[label], item[value]));
        }
        else {
          list.push(new ItemSelect(item[label], item[value], false, true));
        }
      }
    }

    return list;
  }

  getAllLabelValueWithMeta(label: string, value: string, items: Array<any>, campo: string): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status']) {
        list.push(new ItemSelect(item[label], item[value], false, false, item[campo]));
      }
    }

    return list;
  }

  getAllLabelValueSelectedValues(label: string, value: string, items: Array<any>, campo: string, values: Array<string>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status']) {
        if (item.hasOwnProperty(campo) && values.includes(item[campo])) {
          list.push(new ItemSelect(item[label], item[value], true));
        } else {
          list.push(new ItemSelect(item[label], item[value]));
        }
      }
    }

    return list;
  }

  getAllLabelValueSelectedValuesMetadataUser(label: string, value: string, items: Array<any>, campo: string, values: Array<string>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status']) {
        if (item.hasOwnProperty(campo) && values.includes(item[campo])) {
          list.push(new ItemSelect(item[label], item[value], true, false, {cellPhone: item['cellPhone'], email: item['email']}));
        } else {
          list.push(new ItemSelect(item[label], item[value], false, false, {cellPhone: item['cellPhone'], email: item['email']}));
        }
      }
    }

    return list;
  }

  getAllLabelValueSelectedValuesActive(label: string, value: string, items: Array<any>, campo: string, values: Array<string>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('active') && item['active']) {
        if (item.hasOwnProperty(campo) && values.includes(item[campo])) {
          list.push(new ItemSelect(item[label], item[value], true));
        } else {
          list.push(new ItemSelect(item[label], item[value]));
        }
      }
    }

    return list;
  }

  getAllAnalists(label: string, value: string, items: Array<any>, campo: string, values: Array<string>): Array<any> {
    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status'] && item.hasOwnProperty('isAnalyst') && item['isAnalyst']) {
        if (item.hasOwnProperty(campo) && values.includes(item[campo])) {
          list.push(new ItemSelect(item[label], item[value], true));
        } else {
          list.push(new ItemSelect(item[label], item[value]));
        }
      }
    }

    return list;
  }

  getAllAnalist(label: string, value: string, items: Array<any>): Array<any> {
    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status'] && item.hasOwnProperty('isAnalyst') && item['isAnalyst']) {
        list.push(new ItemSelect(item[label], item[value]));
      }
    }

    return list;
  }

  getAllLabelValueSelectedValuesPosition(label: string, value: string, items: Array<any>, campo: string, values: Array<string>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status') && item['status']) {
        if (item.hasOwnProperty(campo) && values.includes(item[campo])) {
          list.push(new ItemSelect(item[label], item[value], true, false, '', item['position']));
        } else {
          list.push(new ItemSelect(item[label], item[value], false, false, '', item['position']));
        }
      }
    }

    return list;
  }

  getAllLabelValueSelectedValuesPositionShowInactives(label: string, value: string, items: Array<any>, campo: string, values: Array<string>): Array<any> {

    let list: ItemSelect[] = [];
    
    for (const item of items) {
      if (item.hasOwnProperty(label) && item.hasOwnProperty(value) && item.hasOwnProperty('status')) {
        if (item.hasOwnProperty(campo) && values.includes(item[campo])) {
          if (item['status']) {
            list.push(new ItemSelect(item[label], item[value], true, false, '', item['position']));
          }
          else {
            list.push(new ItemSelect(item[label], item[value], true, true, '', item['position']));
          }
        } else {
          if (item['status']) {
            list.push(new ItemSelect(item[label], item[value], false, false, '', item['position']));
          }
          else {
            list.push(new ItemSelect(item[label], item[value], false, true, '', item['position']));
          }
        }
      }
    }

    return list;
  }


  isNotEmpty(id: any): boolean {
    return id !== null && id !== undefined && id !== '' && id !== "00000000-0000-0000-0000-000000000000";
  }

  getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors | any = form.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

}
