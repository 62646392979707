import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private path = environment.API_BASE_URL_NOTIFICATION + '/notifier';

  constructor(
    private http: HttpClient
  ) { }
  
  /**
   * 
   * @param accountId 
   * @returns 
   */
  unreadMenssages(accountId: string) : Observable<any> {
    return this.http.get(`${this.path}/uread/accountid/${accountId}`);
    //return this.http.get("assets/data/notification.json");
  }

  /**
   * 
   * @param accountId 
   * @param limit 
   * @param index 
   * @returns 
   */
  getAllPaging(accountId: string, limit: number, index: number) : Observable<any> {
    return this.http.get(`${this.path}/all/accountid/${accountId}?PageSize=${limit}&PageNumber=${index}`);
    //return this.http.get("assets/data/notification.json");
  }

  /**
   * 
   * @param notifierId 
   * @returns 
   */
  updateStatus(notifierId: string) : Observable<any> {
    return this.http.put(`${this.path}/status/notifierId/${notifierId}`, {});
  }

}
