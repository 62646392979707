export class ItemSelect {
    label: string;
    value: string;
    selected?: boolean;
    disabled?: boolean;
    metadata?: any;
    position?: number;

    constructor(label: string, value: string, selected?: boolean, disabled?: boolean, metadata?: any, position?: number) {
      this.label = label;
      this.value = value;
      this.selected = selected;
      this.disabled = disabled;
      this.metadata = metadata;
      this.position = position;
    }
  }