import { INavDataRoles } from '../../shared/interface/sidebar-nav-roles';
import { roles } from '../../shared/constants/roles';

export const navItems: INavDataRoles[] = [
  {
    name: 'Página Inicial',
    url: '/dashboard',
    iconComponent: { name: 'cil-home' }
  },
  {
    title: true,
    name: 'REGULAÇÃO',
    roles: [
      roles.MENU_SINISTER,
      roles.MENU_INSPECTION,
      roles.MENU_DISTRIBUTION,
      roles.MENU_IMPORTS,
      roles.MENU_LIGHTHOUSE
    ]
  },
  {
    name: 'Sinistros (Autos)',
    url: '/regulacao/sinistros',
    iconComponent: { name: 'cil-description' },
    roles: [
      roles.MENU_SINISTER
    ]
  },
  {
    name: 'Controle Análise',
    url: '/controle-analise',
    iconComponent: { name: 'cil-description' },
    roles: [
      roles.MENU_CTRLANALYSE
    ]
  },
  {
    name: 'Monitoramento Autos',
    url: '/controle-atraso',
    iconComponent: { name: 'cil-clock' },
    roles: [
      roles.MENU_SINISTER //MUDAR A PERMISSÃO
    ]
  },
  {
    name: 'Distribuição',
    url: '/regulacao/distribuicao',
    iconComponent: { name: 'cil-swap-horizontal' },
    roles: [
      roles.MENU_DISTRIBUTION
    ]
  },
  {
    name: 'Importações',
    url: '/regulacao/importacao',
    iconComponent: { name: 'cil-cloud-upload' },
    roles: [
      roles.MENU_IMPORTS
    ]
  },
  {
    name: 'Importações Soma',
    url: '/regulacao/importacao-soma',
    iconComponent: { name: 'cil-cloud-upload' },
    roles: [
      roles.MENU_IMPORTS
    ]
  },
  {
    name: 'Farol',
    url: '/regulacao/farol',
    iconComponent: { name: 'cil-balance-scale' },
    roles: [
      roles.MENU_LIGHTHOUSE
    ]
  },
  {
    name: 'Acordos',
    url: '#',
    iconComponent: { name: 'cil-handshake' },
    roles: [
      roles.MENU_AGREEMENT,
      roles.MENU_IMAGE_MONITORING

    ],
    children: [
      {
    name: 'Acordo',
    url: '/regulacao/acordos',
    iconComponent: { name: 'cil-handshake' },
    roles: [
      roles.MENU_AGREEMENT
    ]
  },
  {
        name: 'Monitoramento de Imagens',
        url: '/regulacao/monitor-imagens',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.MENU_IMAGE_MONITORING
        ]
      }
    ]
  },
  {
    name: 'Controle de Acordos',
    url: '/regulacao/busca/acordos',
    iconComponent: { name: 'cil-handshake' },
    roles: [
      roles.MENU_AGREEMENT_SEARCH
    ]
  },
  {
    title: true,
    name: 'GERENCIAL',
    roles: [
      roles.MENU_REPORTS,
      roles.MENU_MONITORING,
      roles.MENU_EXPERTROUTES
    ]
  },
  {
    name: 'Dashboards',
    url: '/relatorios-gerencial',
    iconComponent: { name: 'cil-bar-chart' },
    roles: [
      roles.MENU_REPORTS
    ]
  },
  {
    name: 'Auditória de Sinistros',
    url: '/regulacao-auditoria',
    iconComponent: { name: 'cil-target' },
    roles: [
      roles.MENU_AUDIT_SINISTER
    ]
  },
  {
    name: 'Monitoria',
    url: '/monitoria',
    iconComponent: { name: 'cil-balance-scale' },
    roles: [
      roles.MENU_MONITORING
    ]
  },
  {
    name: 'Roterizador',
    url: '/roteirizador',
    iconComponent: { name: 'cil-map' },
    roles: [
      roles.MENU_EXPERTROUTES
    ]
  },
  {
    title: true,
    name: 'PERSONALIZAÇÃO',
    roles: [
      roles.MENU_ADMINISTRATION,
      roles.MENU_CONFIGURATION
    ]
  },
  {
    name: 'Administração',
    url: '#',
    iconComponent: { name: 'cil-shield-alt' },
    roles: [
      roles.MENU_ADMINISTRATION
    ],
    children: [
      {
        name: 'Contas',
        url: '/administracao/contas',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_ADM_ACCOUNTS
        ]
      },
      {
        name: 'Grupos',
        url: '/administracao/grupos',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_ADM_GROUPS
        ]
      },
      {
        name: 'Permissões',
        url: '/administracao/permissoes',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_ADM_PERMISSIONS
        ]
      },
      {
        name: 'Log',
        url: '/administracao/adminitrativelog',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_ADM_LOGS
        ]
      },
      {
        name: 'Notificações',
        url: '/administracao/grupo-notificacao',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_ADM_NOTIFICATIONS
        ]
      },
    ]
  },
  {
    name: 'Configurações',
    url: '#',
    iconComponent: { name: 'cil-cog' },
    roles: [
      roles.MENU_CONFIGURATION
    ],
    children: [
      {
        name: 'Área',
        url: '/configuracoes/area',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_AREA
        ]
      },
      {
        name: 'Bancos',
        url: '/configuracoes/banco',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_BANK
        ]
      },
      {
        name: 'Feriados',
        url: '/configuracoes/feriados',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_HOLIDAY
        ]
      },
      {
        name: 'Filiais',
        url: '/configuracoes/filiais',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_BRANCH
        ]
      },
      {
        name: 'Seguradora',
        url: '/configuracoes/seguradora',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_INSURERS
        ]
      },
      {
        name: 'Tipo de Negócio',
        url: '/configuracoes/tipo-de-negocio',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_BUSINESSTYPE
        ]
      },
      {
        name: 'Segurado',
        url: '/configuracoes/segurado',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_INSURED
        ]
      },
      {
        name: 'Produtos',
        url: '/configuracoes/produtos',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_BRANCH
        ]
      },
      {
        name: 'Regulador (perito)',
        url: '/configuracoes/regulador',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_EXPERT
        ]
      },
      {
        name: 'Reg. de Atendimentos',
        url: '/configuracoes/atendimento/regioes',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_SERVICEREGIONS
        ]
      },
      {
        name: 'Estágios',
        url: '/configuracoes/estagios',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_STAGES
        ]
      },
      {
        name: 'Situações',
        url: '/configuracoes/situation-agreement',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_SITUATION
        ]
      },
      {
        name: 'Motivo (Acordo)',
        url: '/configuracoes/motivo-acordo',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_REASON
        ]
      },
      {
        name: 'Tipo de Vistoria',
        url: '/configuracoes/tipo-de-vistoria',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_REGULATIONTYPE
        ]
      },
      {
        name: 'Tipo de Documentos',
        url: '/configuracoes/tipo-de-documento',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_DOCUMENTTYPE
        ]
      },
      {
        name: 'Tipo de Imagens',
        url: '/configuracoes/tipo-de-imagens',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_IMAGESTTYPE
        ]
      },
      {
        name: 'Sis. de Orçamentação',
        url: '/configuracoes/orcamentacao',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_BUDGETSYSTEM
        ]
      },
      {
        name: 'Formas de Vistoria',
        url: '/configuracoes/formas-de-vistoria',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_SCHEDULINGTYPE
        ]
      },
      {
        name: 'Agendado com',
        url: '/configuracoes/contato-de-vistoria',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_SCHEDULINGCONTACT
        ]
      },

      {
        name: 'Oficinas',
        url: '/configuracoes/oficina',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_WORKSHOP
        ]
      },
      {
        name: 'Tipo de Veículos (Auto)',
        url: '/configuracoes/veiculo/tipo',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_AUTOTYPE
        ]
      },
      {
        name: 'Marca',
        url: '/configuracoes/veiculo/fabricante',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_AUTOBRAND
        ]
      },
      {
        name: 'Modelo',
        url: '/configuracoes/veiculo/modelo',
        iconComponent: { name: 'cil-media-play' },
        roles: [
          roles.RW_CFG_AUTOMODEL
        ]
      },
    ]
  },
];